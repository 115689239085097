@import "modules/min-order";

// Header
header > .jms-row.section-top-header {
  background: #fff;
  min-height: 0;

  .style-header2 .btn-group {
    min-height: 30px;
    line-height: 30px;
  }
  .item_info {
    line-height: 30px;
  }
}

// Nav
#jms-megamenu-container .jms-megamenu > ul.nav > li > a {
  line-height: 100px;
}

// Search bar
.jms_ajax_search {
  .icon-magnifier {
    position: absolute;
    top: 0;
    bottom: 0;
    right: auto;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    left: 18px;
    @media (max-width: 480px) {
      left: 10px;
    }
  }
  #ajax_search {
    max-width: 250px;
    @media (max-width: 767px) {
      max-width: 170px;
    }
    @media (max-width: 480px) {
      min-height: 25px;
      padding: 0 5px 0 40px;
    }
  }
}
@media (max-width: 480px) {
  .section-header .style-header-3 .addon-box {
    margin-right: 15px;
  }
}

// Qty input
.input-group.quantity-control {
  display: inline-flex;
  margin-right: 10px;
  > span.input-group-addon
  {
    min-width: 30px;
    float: left;
    border-radius: 0;
    padding: 0;
    border: none;
    background: #ebebeb;
    color: #797979;
  }
  > span,
  > input {
    min-height: 45px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid #e6e6e6;
    border-radius: 0;
    margin-left: -1px;
    font-size: 18px;
  }
  > input {
    max-width: 75px;
    width: auto;
    margin: 0 5px;
    border: none;
    background: #ebebeb;
    color: #000;
  }
  > span.input-group-addon:hover {
    background: #58bb00;
    color: #fff;
    border-color: #58bb00;
  }
}

// Product list
#product_list.products-list-in-row  {
  .preview {
    width: 15%;
  }
  .product-preview {
    padding: 10px;
    .extra-comment {
      margin: 5px;
      padding-bottom: 5px;
    }
    .list_info {
      margin-bottom: 5px;
    }
    .preview {
      padding-right: 10px;
    }
  }
  .content_price {
    margin: 0;
    padding: 0;
  }
  .attribute_select {
    display: inline-block;
    height: 45px;
    padding: 0 5px;
  }
  .product-features {
    font-size: 12px;
    label:before {
      content: '';
      height: 5px;
      width: 5px;
      background: #58bb00;
      border-radius: 100%;
      display: inline-block;
      margin-right: 7px;
    }
  }
}

#product_list {
  &.products-list-in-row {
    .product-info {
      .action {
        &>* {
          margin-top: 10px!important;
        }
      }
    }
  }
  &.products-list-in-column {
    .product-info {
      .action {
        &>* {
          margin-top: 10px!important;
        }
      }
    }
    .qty {
      display: inline-block;
      width: 120px;
      height: 40px;
      padding: 2px 5px;
      background: #ebebeb;
      color: #000;
      text-align: center;
      border: 0;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        opacity: 1;
      }
    }
    .product-info {
      min-height: 250px;
    }
  }
  .product-features {
    display: none;
  }
  &.products-list-in-row {
    .product-features {
      display: block;
    }
  }

  .content_porduct_list .style-box .content-action.product-list-style, .content_porduct_list .style-box .content-action.content-cation-style {
    display: block;
  }
}

@media screen and (max-width: 991px) {
  #product_list.products-list-in-row, .view-modes {
    display: block;
  }
}

// Cart
#cart_block.open .shoppingcart-box {
  max-height: 510px;
}