.minimal-purchase {
  border: 1px solid #999999;
  padding: 10px;
  display: inline-block;
  float: right;
  margin-left: 5px;

  .progress-bar-success {
    background: #58bb00;
  }

  .js-minimal-purchase {
    font-weight: bold;
  }

  @media (max-width: 991px) {
    float: none;
    display: block;
    margin: 15px 0;
  }
}

#cart_block {
  .minimal-purchase {
    padding-top: 15px;
    margin: 30px 0 0 0;
    text-align: center;
    font-size: 13px;
    border: 0;
    border-top: 1px solid #f1f1f1;
    text-transform: none;
  }
}